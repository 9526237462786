var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{staticClass:"lg4"},[_c('v-card',[_c('v-app-bar',{staticClass:"hpo_color",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('h5',[_vm._v("Công nợ phải thu")])])],1),_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.InDebt)))])])],1)],1),_c('v-flex',{staticClass:"lg4"},[_c('v-card',[_c('v-app-bar',{staticClass:"hpo_color",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('h5',[_vm._v("Công nợ phải trả")])])],1),_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.OutDebt)))])])],1)],1),_c('v-flex',{staticClass:"lg4"},[_c('v-card',[_c('v-app-bar',{staticClass:"hpo_color",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('h5',[_vm._v("Tổng công nợ")])])],1),_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.TotalDebt)))])])],1)],1)],1)],1),_c('v-flex',{attrs:{"lg12":""}},[_c('hpo-filter',{attrs:{"headers":_vm.headers,"viewName":_vm.viewName},on:{"applyFilter":_vm.applyFilter,"quickFilter":_vm.quickFilter}}),_c('v-data-table',{attrs:{"fixed-header":"","height":"750px","headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.total_rows,"footer-props":_vm.items_per_page,"must-sort":true,"loading":_vm.loading,"mobile-breakpoint":100},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('table-header-filter',{attrs:{"headers":props.headers,"viewName":_vm.viewName},on:{"applyFilter":_vm.applyFilter}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.UID))]),_c('td',[_vm._v(_vm._s(item.Username))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.TotalAmount)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.TotalPayment)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.Wallet)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.Debt)))]),_c('td',{staticClass:"truncate"},[_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"light-blue lighten-2 black--text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.Note))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Note))])])],1),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""},on:{"click":function($event){return _vm.gotoDetail(item.UID)}}},on),[_vm._v("fa-external-link-alt")])]}}],null,true)},[_c('span',[_vm._v("Chi tiết công nợ")])])],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }